import React, { Component } from 'react'
import { navigate } from 'gatsby'
import axios from 'axios'
import {
  AuthForm,
  FormInputLoginContainer,
  StyledLabel,
  LoginDecorator,
  SubmitButton
} from '../../StylesPages/loginStyle'
import { TitleCard } from '../../StylesPages/membershipStyle'
import { InputContainer, StyledInput, StyledError, CustomButton } from '../../Collapse/stepped/style'
import { ButtonContainer } from '../../Button/style'

class RegistrationSection extends Component<{}, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      error: null,
      identification: ``
    }
    this.register.bind(this)
  }

  handleUpdate = (event: any) => {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleValidation = () => {
    let formIsValid = true
    if (!this.state.identification) {
      formIsValid = false
      this.setState({ error: 'Identificación requerida' })
    }
    return formIsValid
  }

  register = async (identification: string) => {
    await Promise.all([
      axios.get(`${process.env.API_URL}customer/check-existing-customer`, {
        params: { identification }
      }),
      axios.get(`${process.env.API_URL}customer/check-registered-user`, {
        params: { identification }
      })
    ])
      .then(([existingCustomer, registeredUser]) => {
        let customerId: number = existingCustomer.data.customerId
        let isRegistered: boolean = registeredUser.data.isRegistered
        if (customerId) {
          return navigate('/generate-password', { state: { customerId } })
        }
        if (isRegistered) {
          return navigate('/login')
        }
        navigate('/membership', { state: { userNotFound: true } })
      })
      .catch(error => {
        throw error
      })
  }

  render() {
    return (
      <AuthForm
        display
        method="post"
        onSubmit={(event: any) => {
          event.preventDefault()
          if (this.handleValidation()) {
            this.register(this.state.identification)
          }
        }}
      >
        <TitleCard className="center-title">Registro</TitleCard>
        <FormInputLoginContainer>
          <StyledLabel>Número de identificación</StyledLabel>
          <InputContainer>
            <StyledInput
              placeholder="Ingresa tu número de identificación"
              error={this.state.error}
              name="identification"
              onChange={this.handleUpdate}
            />
            {this.state.error && <StyledError>{this.state.error}</StyledError>}
          </InputContainer>
        </FormInputLoginContainer>
        <ButtonContainer>
          <SubmitButton type="submit" value="Registrarme" />
        </ButtonContainer>
        <LoginDecorator className="row">
          <div className="line" />
          <div>
            <i className="icon-grande-table-02" />
          </div>
          <div className="line" />
        </LoginDecorator>
        <div className="register-text">¿Ya está registrado?</div>
        <ButtonContainer className="secondary-btn-container">
          <CustomButton className="secondary-btn-brown" onClick={() => navigate('/login')}>
            Iniciar sesión
          </CustomButton>
        </ButtonContainer>
      </AuthForm>
    )
  }
}
export default RegistrationSection
